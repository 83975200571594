<template>
  <div v-if="magicCode !== null">
    <a
      :href="getStudentViewURL"
      class="undecorated-router-link"
      :target="openInNewWindow ? '_blank' : ''"
      @click="markAsSeen"
    >
      <CommunityNotificationUI :notification="notification" />
    </a>
  </div>
  <div v-else @click="markAsSeen(notification)">
    <CommunityNotificationUI :notification="notification" />
  </div>
</template>

<script>
import CommunityNotificationUI from './CommunityNotificationUI.vue';
import {
  getStudentViewURL,
} from '@/utils/custom-domain-utils';
import { useCommunityStore } from '@/stores';

export default {
  name: 'CommunityNotificationItem',
  components: {
    CommunityNotificationUI,
  },
  props: {
    notification: {
      type: Object,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    magicCode: {
      type: String,
      default: null,
    },
    isStudio: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const url = useRequestURL();
    return {
      url
    };
  },
  computed: {
    extraData() {
      return this.notification?.extraData;
    },
    getRouteName() {
      if (!this.notification) return null;

      if (this.extraData?.playlist_id) {
        return 'studio-playlist-media';
      }
      if (this.extraData?.lesson_id) {
        return 'studio-lesson-media';
      }
      if (this.extraData?.group_id) {
        return 'studio-community-group';
      }
      return 'studio-media';
    },
    getStudioUrlFromNotification() {
      return this.extraData?.studio_url;
    },
    getRouteParams() {
      if (this.extraData?.group_id) {
        return {
          studio_url: this.extraData?.studio_url,
          group_id: this.extraData?.group_id,
        };
      }
      return {
        ...this.extraData,
        media_id: this.extraData?.entity_id,
      };
    },
    getRouteQueryParams() {
      return {
        comment: this.notification?.object?.id,
      };
    },
    forceMarvelousDomain() {
      return this.$studioURL !== this.getStudioUrlFromNotification;
    },
    getRouteUrl() {
      const params = this.getRouteParams;
      if (this.getStudioUrlFromNotification) {
        params.studio_url = this.getStudioUrlFromNotification;
      }
      return this.$router.resolve({
        name: this.getRouteName,
        params: params,
        query: this.getRouteQueryParams,
      });
    },
    getStudentViewURL() {
      const routeUrl = this.getRouteUrl.href.replaceAll(
        `${this.getStudioUrlFromNotification}/`,
        ''
      );
      return getStudentViewURL(
        this.url,
        this.getStudioUrlFromNotification,
        routeUrl,
        this.magicCode,
        this.forceMarvelousDomain
      );
    },
  },
  methods: {
    markAsSeen() {
      console.log('markAsSeen', this.notification);
      if (this.notification.isSeen) return;

      try {
        useCommunityStore().markNotificationSeen({
          notificationId: this.notification.id,
          isStudio: this.isStudio,
        });
        this.notification.isSeen = true;
      } catch (error) {
        console.error('Error marking notification as seen', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
</style>
