<template>
  <div class="notifications">
    <div class="notifications__header pt-3 pb-2 px-3 mb-2">
      {{ $t('COMMUNITY.LABEL.NOTIFICATIONS') }}
      <div
        v-if="hasUnseenNotifications"
        class="pointer"
        @click="markSeenClicked"
        v-tooltip="{
          content: 'Mark all as read',
          delay: 100,
          placement: 'top',
          container: 'body',
          trigger: 'click hover',
        }"
      >
        <i class="far fa-check-double"></i>
      </div>
    </div>
    <div
      class="notifications__content pt-1 pb-2 px-3"
    >
      <div
        v-for="notification in parsedNotifications"
        :key="notification.id"
        class="notification__block pb-2"
      >
        <CommunityNotificationItem
          :notification="notification"
          :openInNewWindow="openInNewWindow"
          :magicCode="magicCode"
          :isStudio="isStudio"
        />
      </div>
      <div class="text-center" v-if="isLoading">
        <HMSpinner></HMSpinner>
      </div>
      <InfiniteLoading distance="10" @infinite="loadMoreNotifications" :firstload="false">
        <template #spinner>
          <div class="d-flex justify-content-center">
            <HMSpinner></HMSpinner>
          </div>
        </template>
        <template #complete>
          <div></div>
        </template>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script lang="ts">
import CommunityNotificationItem from './CommunityNotificationItem.vue';
import { useCommunityStore } from '@/stores';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default {
  name: 'CommunityNotifications',
  components: {
    CommunityNotificationItem,
    InfiniteLoading,
  },
  props: {
    isStudio: {
      type: Boolean,
      default: false,
    },
    openInNewWindow: {
      type: Boolean,
      default: false,
    },
    magicCode: {
      type: String,
      default: null,
    },
  },
  computed: {
    notifications() {
      return this.isStudio
        ? useCommunityStore().studioNotifications
        : useCommunityStore().notifications;
    },
    canLoadMoreNotifications() {
      return this.isStudio
        ? useCommunityStore().canLoadMoreStudioNotifications
        : useCommunityStore().canLoadMoreNotifications;
    },
    parsedNotifications() {
      const result = [];
      const results = this.notifications?.results || [];
      results.forEach(n => {
        const actorCount = n.actor_count;
        const activityCount = n.activity_count;
        const verb = n.verb;
        const firstActivity = n.activities[0];
        result.push({
          verb: verb,
          time: firstActivity.time,
          actor: firstActivity.actor,
          actorCount: actorCount,
          activityCount: activityCount,
          extraData: firstActivity.extra_data,
          object: firstActivity.object,
          activities: n.activities,
          isSeen: n.is_seen,
          id: n.id,
        });
      });

      return result;
    },
    hasUnseenNotifications() {
      return this.parsedNotifications.some(
        notification => !notification.isSeen
      );
    },
  },
  data() {
    return {
      isLoading: true,
      markSeen: false,
    };
  },
  methods: {
    getLoadNotificationsAction() {
      return this.isStudio
        ? useCommunityStore().loadStudioNotifications
        : useCommunityStore().loadNotifications;
    },
    getLoadNextNotificationsAction() {
      return this.isStudio
        ? useCommunityStore().loadNextStudioNotifications
        : useCommunityStore().loadNextNotifications;
    },
    markSeenClicked() {
      this.markSeen = true;
      this.loadNotifications();
    },
    async loadNotifications() {
      this.isLoading = true;
      try {
        await this.getLoadNotificationsAction()({
          markSeen: this.markSeen,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async loadMoreNotifications($state) {
      if (!this.canLoadMoreNotifications) $state.complete();
      try {
        await this.getLoadNextNotificationsAction()({
          markSeen: this.markSeen,
        });
        if (!this.canLoadMoreNotifications) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.error();
      }
    },
  },
  mounted() {
    this.loadNotifications();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.notifications {
  width: 413px;
  color: $charcoal;
  border: 1px solid $medium-gray;
  border-radius: 6px;
  background: $white;
  z-index: 1033;
  box-shadow: -5px 5px 7px rgb(50 50 50 / 20%);

  @media screen and (max-width: 760px) {
    width: 100%;
  }

  &__header {
    color: $charcoal;
    font-weight: 600;
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .notifications__content {
    height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $light-gray;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $light-gray;
    }
  }
}
</style>
